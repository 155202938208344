import React from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import Layout from '@/layouts/MainLayout'
import SEO from '@/components/seo'
import { CategoryCascad } from '../cascad-screens/CategoryCascad'
import { getInfinityQuery } from '../utils/getInfinityQuery'
import { NewsContent } from '../components/NewsContent'
import { getHeadingsQuery } from '../api/headings'
import { useInfiniteQuery } from 'react-query'
import { navigate } from 'gatsby'

const Headings = () => {
  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
    isFetching,
    isFetched,
  } = useInfiniteQuery(
    ['headings'],
    ({ pageParam }) => getInfinityQuery(getHeadingsQuery, pageParam),
    {
      getNextPageParam: lastPage => lastPage.next,
      onError: () => navigate('/404'),
    }
  )
  const dataLength = data?.pages[data?.pages.length - 1].results.length

  return (
    <Layout>
      <SEO title='Рубрики' />

      {!isFetched && (isLoading || isFetching) ? (
        <CategoryCascad isHeading count={9} hasHeader={false} />
      ) : (
        <InfiniteScroll
          dataLength={dataLength}
          next={fetchNextPage}
          hasMore={hasNextPage}
          style={{ overflow: 'hidden' }}
          loader={
            isFetchingNextPage && <CategoryCascad count={9} hasHeader={false} />
          }
        >
          {dataLength && (
            <NewsContent
              heading='Рубрики'
              data={data?.pages}
              dataLength={dataLength}
              isHeading
            />
          )}
        </InfiniteScroll>
      )}
    </Layout>
  )
}

export default Headings
