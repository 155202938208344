import request from '@/services/request'
import { HEADINGS_URL } from '../services/constans'

export const getHeadingsQuery = async ({ limit = 6, page }) => {
  const { data } = await request({
    url: HEADINGS_URL,
    method: 'get',
    params: {
      page,
      limit,
    },
  })
  return data
}
